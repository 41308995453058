import React from "react"
import styled from "styled-components"
import color from "../../style/color"
import { headingFontLight } from "../../style/typography"
import Button from "../../shared/Button/Button"
import { getItem } from "../../util/functions"
import {
  Container,
  Text,
  Heading,
  Light,
  ConfirmationText,
} from "./ConfirmationComponent"

const SmileFormConfirmation = () => {
  const getConcerns = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const list = getItem("concern") ? getItem("concern").split(", ") : ""
      const firstList = list.slice(0, list.length - 1).join(", ")
      return (
        <>
          <span>{firstList || list[0]}</span>
          {list.length > 1 && (
            <>
              {" "}
              and <span>{list[list.length - 1]}</span>
            </>
          )}
        </>
      )
    }
  }
  return (
    <Container>
      <Text>
        <Heading>
          Hi {getItem("firstName")} {getItem("lastName")},
        </Heading>
        <Light>Thank you for getting in touch</Light>
        <ConfirmationText>
          {!getItem("concern") ? (
            <>
              A member of our{" "}
              <span>White and Co {getItem("smile-form-dropdown1")}</span> team
              will be in contact soon to discuss your enquiry.
            </>
          ) : (
            <>
              {" "}
              A member of our{" "}
              <span>White and Co {getItem("smile-form-dropdown1")}</span> team
              will be in contact soon to discuss your enquiry on {getConcerns()}
              .
            </>
          )}
        </ConfirmationText>
      </Text>
      <Button text="Continue" href={process.env.BASE} formConfirmation />
    </Container>
  )
}

export default SmileFormConfirmation
